@tailwind base;

@tailwind components;

@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&family=Poppins:wght@400;600;700&family=Roboto:wght@400;500;600;700&display=swap');


.card-zoom {
  @apply relative flex items-center justify-center overflow-hidden shadow-xl h-48 w-full;
}
.card-zoom-image {
  @apply absolute w-full h-full transition-all duration-1000 ease-in-out transform bg-center bg-cover;
}
.card-zoom-text {
  letter-spacing: 4px !important;
  @apply text-[#0172BB] absolute top-12 invisible capitalize font-medium;
}
.card-zoom:hover .card-zoom-image {
  @apply scale-150;
}
.card-zoom:hover .card-zoom-text {
  @apply visible duration-1000 translate-y-12 tracking-widest;
}

.card-zoom:hover .card-content {
  @apply opacity-80 cursor-pointer;
}

.card-content {
  @apply bg-white h-full w-full opacity-0;
}

.login-btn {
  background-color: #2686c4;
}

.logout-btn {
  background-color: #6826c4;
}
.info-btn {
  background-color: #1aa39a;
}
.bg-grdnt {
  background-image: linear-gradient(to right, #6DC7DD, #1A79C1);
  border-radius: 20px !important;
  top: 340px;
}

.bg-gradeint{
  background-image: linear-gradient(to right, #6DC7DD, #1A79C1);
}

.header-menu,.mobile-nav {
  background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(3px);
    z-index:1000!important
}

.bg-schedule{
  background-color: rgba(1, 113, 185, 0.07);
}

.home-slider{
  height:350px 
}

.header-container {
  background-color: #323C8E;
  backdrop-filter: blur(3px);
}
#header-container {
  background-color: #323C8E;
}
@media (min-width: 640px ) {
  .home-slider {
    height: 350px !important;
  }
  .bg-grdnt {
    top: 350px !important;
  }
}

@media (min-width: 768px) {
  .home-slider {
    height: 400px !important;
  }
  .bg-grdnt {
    top: 400px !important;
  }
  .header-container {
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(3px);
  }
}
@media (min-width: 1024px) {
  .home-slider {
    height: 555px !important;
  }
  .bg-grdnt {
    top: 550px !important;
  }
}
@media (min-width: 1280px) {
  .home-slider {
    height: 690px !important;
  }
  .bg-grdnt {
    top: 690px !important;
  }
}
@media (min-width: 1536px) {
  .home-slider {
    height: 690px !important;
  }
  .bg-grdnt {
    top: 690px !important;
  }
}

.error {
  background-color: #EBB1B1;
  border-color: #973939;
  color: #973939;
}

.success {
  background-color: #B9FFAB;
  border-color: #116400;
  color: #116400;
}

.box {
  height: 20px;
  border: 2px solid #469DC3;
  margin: 0 auto 15px;
  text-align: center;
  padding: 20px;
  font-weight: bold;
  border-radius: 10px;
  line-height: 0;
}

.box1 {
  margin-top: 10px;
  height: auto;
  line-height: 15px;
}
.video-wrapper {
  display: flex;
  text-align: center;
  justify-content: center;
  
}